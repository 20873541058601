jQuery(document).ready(function($){

$('#nav-icon').on('click',function(e){
    $(this).toggleClass('open');
    $('#nav-menu').toggleClass('open');

});
    $('.menu-overlay').on('click',function(e){
        $('#nav-icon').toggleClass('open');
        $('#nav-menu').toggleClass('open');
    });
    $('.third-carousel').owlCarousel({
        items:6,
        margin:30,
        autoHeight:true,
        nav: false,

        responsive : {
            0 : {
                items:1,
                nav:false,
                dot: true,
            },
            748 : {
                items:3,
                nav:false,
                dot: true,
            },
            1140:{
                items:6,
                nav:false,
                loop:false
            }
        }
    });

    $('.new_owl_carousel').owlCarousel({
        margin:30,
        autoHeight:true,
        responsive : {
            0: {
                items: 1,
                nav: false,
                dot: true,
            },
            748: {
                items: 2,
                nav: false,
                dot: true,
            },
        }
    });

    let phoneno = /^([+]?[0-9\s-\(\)]{3,25})*$/i;
    AOS.init();
    let app = new Vue({
        el: '.start__form',
        data: {
            formData: true,
            startPhone: null,
            errors: null,
            mainForm: true,
            loader: false,

        },
        methods: {
            OnSend: function (e) {
                e.preventDefault();
                this.errors = [];
                let Error = false;
                if (!phoneno.test(this.startPhone) ) {
                    Error = true;
                    this.errors = [];
                    this.errors.push('Это не похоже на номер. Пример телефона +38(099) 123-45-67 или +38 093 123-45-67');
                } else {
                    Error = false;
                    this.errors = [];
                }
                if (!Error) {
                    this.mainForm = false;
                    this.loader = true;


                    jQuery.post(
                        "/mail/mail.php",
                         {

                             phone: app.startPhone
                            },
                        )
                        .done(function( data ) {
                            if(JSON.parse(data).success ){
                                app.loader = false;
                                app.mainForm= true;
                                jQuery('#successModal').modal('show');
                            }

                        });

                }


            },
            checkPhone(){

                if (!phoneno.test(this.startPhone) ) {
                    this.errors = [];
                    this.errors.push('Это не похоже на номер. Пример телефона +38(099) 123-45-67 или +38 093 123-45-67');
                } else {
                    this.errors = [];
                }
            },
            closeForm(){
                app.formData = false;
            }
        },

    });
    let app2 = new Vue({
        el: '.modal__form',
        data: {
            startPhone: null,
            errors: null,
            mainForm: true,
            loader: false,
        },
        methods: {
            OnSend: function (e) {
                e.preventDefault();
                this.errors = [];
                let Error = false;
                if (!phoneno.test(this.startPhone) ) {
                    Error = true;
                    this.errors = [];
                    this.errors.push('Это не похоже на номер. Пример телефона +38(099) 123-45-67 или +38 093 123-45-67');
                } else {
                    this.errors = [];
                }
                if (!Error) {
                    this.mainForm = false;
                    this.loader = true;



                    jQuery.post(
                        "/mail/mail.php",
                        {

                            phone: app2.startPhone
                        },
                    )
                        .done(function( data ) {
                            if(JSON.parse(data).success ){
                                app2.loader = false;
                                app2.mainForm = true;
                                jQuery('#footerModal').modal('hide');
                                setTimeout(function(){
                                    jQuery('#successModal').modal('show');
                                },500)

                            }

                        });

                }


            },
            checkPhone(){

                if (!phoneno.test(this.startPhone) ) {
                    this.errors = [];
                    this.errors.push('Это не похоже на номер. Пример телефона +38(099) 123-45-67 или +38 093 123-45-67');
                } else {
                    this.errors = [];
                }
            },
        },

    });
    let Viber = new Vue({
        el: '.contact__data',
        data: {
            link: null,
            windowWidth: null,
            mailto: null
        },
        methods:{
            handleResize() {
                this.windowWidth = window.innerWidth;
            },
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        computed: {
            isMobile: function () {
                if (this.windowWidth > 991) {
                    return 'viber://chat?number=+380508156581';
                } else {
                    return 'viber://add?number=380508156581';
                }

            },
            getMail: function(){
                return "mailto:hello@woodfence.kiev.ua"
            }
        }
    });
    $('a[href*=\\#]').on('click', function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
    });
});


$(window).on("load",function(){
    //custom scroll line in window

});
